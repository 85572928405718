import React from "react"

import Fade from "react-reveal/Fade"

import SEO from "../components/seo"
import Hero from "../components/sections/hero/Hero"
import Offer from "../components/sections/offer/Offer"
import Newcomers from "../components/sections/newcomers/Newcomers"
import Services from "../components/sections/services/Services"
import Report from "../components/sections/report/Report"
import Footer from "../components/footer /Footer"
import Header from "../components/header/Header"
import Reviews from "../components/sections/reviews/Reviews"
import WindowWidthProvider from "../utils/window-width-provider/WindowWidthProvider"

const IndexPage = () => (
  <>
    <SEO title="Home"/>

    <WindowWidthProvider>
      <Header/>
      <Hero/>
      <Fade bottom distance={"30px"}>
        <Offer/>
      </Fade>
      <Fade bottom distance={"30px"}>
        <Newcomers/>
      </Fade>
      <Fade bottom distance={"30px"}>
        <Services/>
      </Fade>
      <Fade bottom distance={"30px"}>
        <Report/>
      </Fade>
      <Fade bottom distance={"30px"}>
        <Reviews/>
      </Fade>
      <Footer/>
    </WindowWidthProvider>
  </>
)

export default IndexPage
