import React, { FC, HTMLAttributes, ReactElement } from "react"
import classNames from "classnames"

import styles from "./Title.module.scss"

interface TitleInterface extends HTMLAttributes<HTMLElement> {
}

const Title: FC<TitleInterface> = ({
                                     children,
                                     className
                                   }): ReactElement => {
  return (
    <h2 className={classNames(styles.wrap, className)}>
      {children}
    </h2>
  )
}

export default Title
