import React, { ButtonHTMLAttributes, FC, ReactElement } from "react"
import { Link } from "gatsby"
import classNames from "classnames"

import styles from "./Button.module.scss"

interface ButtonInterface extends ButtonHTMLAttributes<HTMLButtonElement> {
  reverse?: boolean
  link?: string
  wide?: boolean
  small?: boolean
}

const Button: FC<ButtonInterface> = (props): ReactElement => {
  const { children, reverse, link, small, wide, className, ...rest } = props

  return (
    <>
      {children && link ? (
        <Link
          to={link}
          className={classNames(
            styles.wrap,
            className,
            small && styles.small,
            wide && styles.wide,
            reverse && styles.reverse
          )}
        >
          {children}
        </Link>
      ) : children ? (
        <button
          {...rest}
          className={classNames(
            styles.wrap,
            className,
            small && styles.small,
            wide && styles.wide,
            reverse && styles.reverse
          )}
        >
          {children}
        </button>
      ) : null}
    </>
  )
}

export default Button
