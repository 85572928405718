import React, { FC, HTMLAttributes, ReactElement } from "react"
import classNames from "classnames"

import styles from "./NewcomersList.module.scss"

interface NewcomersListInterface extends HTMLAttributes<HTMLElement> {
}

const NewcomersList: FC<NewcomersListInterface> = ({
                                                     children,
                                                     className
                                                   }): ReactElement => {
  return (
    <ul className={classNames(styles.wrap, className)}>
      {children}
    </ul>
  )
}

export default NewcomersList
