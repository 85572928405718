import React, { FC, ReactElement } from "react"

import Input from "../../../../ui/form-elements/input/Input"
import Select from "../../../../ui/form-elements/select/Select"
import Radio from "../../../../ui/form-elements/radio/Radio"
import Button from "../../../../ui/button/Button"

import styles from "./HeroForm.module.scss"

const HeroForm: FC = (): ReactElement => {
  return (
    <form className={styles.wrap}>
      <fieldset className={styles.fieldset}>
        <legend className={styles.legend}>
          Find the best place for you in...
        </legend>
        <Input icon="pin" placeholder="Select from the list or enter a city" />
        <Select options={["Apartments", "Houses", "Garden plots"]} />
        <Select
          options={[
            "Price",
            "500$ – 1000$",
            "1000$ – 1500$",
            "1500$ – 2000$",
            "2500$ – 3000$",
          ]}
        />
        <Select
          options={[
            "Footage",
            "20m\u00B2  – 35m\u00B2",
            "35m\u00B2  – 50m2\u00B2",
            "50m\u00B2  – 65m\u00B2",
            "65m\u00B2  – 75m\u00B2",
            "75m\u00B2  – 90m\u00B2",
          ]}
        />
        <Select options={["Rooms", "1", "2", "3", "4", "5+"]} />

        <div className={styles["radio__wrap"]}>
          <Radio name="deal_type" id="rent" defaultChecked label="For rent" />
          <Radio name="deal_type" id="sale" label="For sale" />
        </div>
      </fieldset>

      <div className={styles.actions}>
        <Button wide>Search</Button>
      </div>
    </form>
  )
}

export default HeroForm
