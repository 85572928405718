import React, { FC, LabelHTMLAttributes, ReactElement } from "react"
import classNames from "classnames"

import styles from "./Label.module.scss"

interface LabelInterface extends LabelHTMLAttributes<HTMLLabelElement> {

}

const Label: FC<LabelInterface> = ({
                                     children,
                                     htmlFor,
                                     className
                                   }): ReactElement => {
  return (
    <label
      htmlFor={htmlFor}
      className={classNames(styles.wrap, className)}
    >
      {children}
    </label>
  )
}

export default Label
