import React, { FC, ReactElement } from "react"
import { Link } from "gatsby"
import classNames from "classnames"

import FooterLinksTitle from "../footer-links-title/FooterLinksTitle"

import styles from "./FooterSocialLinks.module.scss"

interface SocialLinkInterface {
  name: "facebook" | "instagram" | "twitter" | "youtube",
  path: string
}

type SocialLinksInterface = SocialLinkInterface[];

const FooterSocialLinks: FC = (): ReactElement => {
  const links: SocialLinksInterface = [
    {
      name: "facebook",
      path: "/"
    },
    {
      name: "instagram",
      path: "/"
    },
    {
      name: "twitter",
      path: "/"
    },
    {
      name: "youtube",
      path: "/"
    }
  ]

  return (
    <div className={styles.wrap}>
      <FooterLinksTitle className={styles.title}>
        You can find us on
      </FooterLinksTitle>

      {links && (
        <ul className={styles.links}>
          {links.map((
            {
              name,
              path
            }, index) => (
            <Link
              key={index}
              className={styles.link}
              to={path}
            >
              <i className={classNames("hidden-text", `icon-${name}`, styles.icon)}>
                name
              </i>
            </Link>
          ))}
        </ul>
      )}

      <Link
        className={styles.email}
        to="/"
      >
        @flatstep.estate
      </Link>
    </div>
  )
}

export default FooterSocialLinks
