import React, { FC, ReactElement } from "react"

import styles from "./HeroInfo.module.scss"

const HeroInfo: FC = (): ReactElement => {
  return (
    <div className={styles.wrap}>
      <h1 className={styles.title}>Ready to find your new home?</h1>

      <div className={styles.description}>
        Choose the best place for yourself from 25.000
        apartments and houses all over Poland.
      </div>
    </div>
  )
}

export default HeroInfo
