import React, { FC, HTMLAttributes, ReactElement } from "react"
import classNames from "classnames"

import styles from "./FormItem.module.scss"

interface FormItemInterface extends HTMLAttributes<HTMLElement> {
}

const FormItem: FC<FormItemInterface> = ({
                                           children,
                                           className
                                         }): ReactElement => {
  return (
    <div className={classNames(styles.wrap, className)}>
      {children}
    </div>
  )
}

export default FormItem
