import React, { FC, ReactElement } from "react"
import classNames from "classnames"
import { graphql, useStaticQuery } from "gatsby"

import Title from "../../ui/title/Title"
import Description from "../../ui/description/Description"
import Button from "../../ui/button/Button"
import NewcomersItem, { NewcomersItemInterface } from "./components /newcomers-item/NewcomersItem"
import NewcomersList from "./components /newcomers-list/NewcomersList"
import { ImgGraphQlResponseType } from "../../../utils/interfaces"

import styles from "./Newcomers.module.scss"

const Newcomers: FC = (): ReactElement => {
  const images: ImgGraphQlResponseType = useStaticQuery(graphql`
      query {
          image1: file(relativePath: { eq: "newcomers/newcomer-1.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 300) {
                      aspectRatio
                      src
                      srcSet
                      srcWebp
                      srcSetWebp
                      sizes
                  }
              }
          },
          image2: file(relativePath: { eq: "newcomers/newcomer-2.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 300) {
                      aspectRatio
                      src
                      srcSet
                      srcWebp
                      srcSetWebp
                      sizes
                  }
              }
          },
          image3: file(relativePath: { eq: "newcomers/newcomer-3.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 300) {
                      aspectRatio
                      src
                      srcSet
                      srcWebp
                      srcSetWebp
                      sizes
                  }
              }
          }
      }
  `)

  // Fake data
  const newcomersData: NewcomersItemInterface[] = [
    {
      image: images["image1"]["childImageSharp"]["fluid"],
      title: "Cosy flat in Srodmiescie",
      location: "Warsaw, Centrum",
      price: "1025$"
    },
    {
      image: images["image2"]["childImageSharp"]["fluid"],
      title: "Private studio on Puławska...",
      location: "Warsaw, Mokotów, Puławska",
      price: "945$"
    },
    {
      image: images["image3"]["childImageSharp"]["fluid"],
      title: "Lovely flat, excellent location",
      location: "Warsaw, Muranów, Dzielna",
      price: "1720$"
    }
  ]

  // Newcomers elements
  const newcomersElements: ReactElement[] = newcomersData.map((props, index) => (
    <NewcomersItem
      {...props}
      key={index}
    />
  ))

  return (
    <section className={classNames("container", "lg", styles.wrap)}>
      <div className={classNames("container", styles["wrap-content"])}>
        <div className={styles.info}>
          <Title>New offers</Title>
          <Description className={styles.desc}>
            About 120 new ads are posted on this platform daily. <br/>
            That’s a lot. We don’t even know where did
            people get so many apartments? Magic. Discover a range of new real
            estate developments across Poland. Find the
            best.
          </Description>

          <Button
            link="/"
            className={styles.btn}
          >
            Check out newcomers
          </Button>
        </div>

        <NewcomersList>
          {newcomersElements}
        </NewcomersList>
      </div>
    </section>
  )
}

export default Newcomers
