import React, { FC, ReactElement } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Slider from "react-slick"
import classNames from "classnames"

import ReviewItem, { ReviewItemInterface } from "../../reviews/review-item/ReviewItem"
import Title from "../../ui/title/Title"
import Description from "../../ui/description/Description"
import Counter from "../../counter/Counter"
import { ImgGraphQlResponseType } from "~/utils/interfaces"
import { Consumer as WindowWidthConsumer, tablet } from "../../../utils/window-width-provider/WindowWidthProvider"

import styles from "./Reviews.module.scss"

const Reviews: FC = (): ReactElement => {
  const carouselImages: ImgGraphQlResponseType = useStaticQuery(graphql`
      query {
          image1: file(relativePath: { eq: "reviews/review-1.png" }) {
              childImageSharp {
                  fluid(maxWidth: 40) {
                      aspectRatio
                      src
                      srcSet
                      srcWebp
                      srcSetWebp
                      sizes
                  }
              }
          },
          image2: file(relativePath: { eq: "reviews/review-2.png" }) {
              childImageSharp {
                  fluid(maxWidth: 40) {
                      aspectRatio
                      src
                      srcSet
                      srcWebp
                      srcSetWebp
                      sizes
                  }
              }
          },
          image3: file(relativePath: { eq: "reviews/review-3.png" }) {
              childImageSharp {
                  fluid(maxWidth: 40) {
                      aspectRatio
                      src
                      srcSet
                      srcWebp
                      srcSetWebp
                      sizes
                  }
              }
          },
          image4: file(relativePath: { eq: "reviews/review-4.png" }) {
              childImageSharp {
                  fluid(maxWidth: 40) {
                      aspectRatio
                      src
                      srcSet
                      srcWebp
                      srcSetWebp
                      sizes
                  }
              }
          },
          image5: file(relativePath: { eq: "reviews/review-5.png" }) {
              childImageSharp {
                  fluid(maxWidth: 40) {
                      aspectRatio
                      src
                      srcSet
                      srcWebp
                      srcSetWebp
                      sizes
                  }
              }
          },
          image6: file(relativePath: { eq: "reviews/review-6.png" }) {
              childImageSharp {
                  fluid(maxWidth: 40) {
                      aspectRatio
                      src
                      srcSet
                      srcWebp
                      srcSetWebp
                      sizes
                  }
              }
          },
          image7: file(relativePath: { eq: "reviews/review-7.png" }) {
              childImageSharp {
                  fluid(maxWidth: 40) {
                      aspectRatio
                      src
                      srcSet
                      srcWebp
                      srcSetWebp
                      sizes
                  }
              }
          },
          image8: file(relativePath: { eq: "reviews/review-8.png" }) {
              childImageSharp {
                  fluid(maxWidth: 40) {
                      aspectRatio
                      src
                      srcSet
                      srcWebp
                      srcSetWebp
                      sizes
                  }
              }
          },
          image9: file(relativePath: { eq: "reviews/review-9.png" }) {
              childImageSharp {
                  fluid(maxWidth: 40) {
                      aspectRatio
                      src
                      srcSet
                      srcWebp
                      srcSetWebp
                      sizes
                  }
              }
          }
      }
  `)
  const firstCarousel: ReviewItemInterface[] = [
    {
      name: "Neville Longbottom",
      role: "Property owner",
      description: "Places helps eliminate a huge point of friction when it " +
        "comes to finding office spaces and negotiating with landlords.",
      photo: carouselImages["image1"]["childImageSharp"]["fluid"],
      rating: 5
    },
    {
      name: "Tudor Robison",
      role: "Property owner",
      description: "I wanted to avoid unnecessary troubles and find the right" +
        " tenant. And it worked. Because only rich hipsters visit this site.",
      photo: carouselImages["image2"]["childImageSharp"]["fluid"],
      rating: 4
    },
    {
      name: "Rosana Espinosa",
      role: "Tenant",
      description: "This is the only service that gives certainty" +
        " that I don't have to kiss rats at night. Not anymore. Brrr.",
      photo: carouselImages["image3"]["childImageSharp"]["fluid"],
      rating: 5
    },
    {
      name: "Neville Longbottom",
      role: "Property owner",
      description: "This website helps eliminate a huge point of " +
        "friction when it comes to finding office spaces and negotiating w",
      photo: carouselImages["image4"]["childImageSharp"]["fluid"],
      rating: 5
    },
    {
      name: "Neville Longbottom",
      role: "Property owner",
      description: "Places helps eliminate a huge point of friction when it " +
        "comes to finding office spaces and negotiating with landlords.",
      photo: carouselImages["image5"]["childImageSharp"]["fluid"],
      rating: 4
    },
    {
      name: "Rosana Espinosa",
      role: "Tenant",
      description: "This is the only service that gives certainty" +
        " that I don't have to kiss rats at night. Not anymore. Brrr.",
      photo: carouselImages["image3"]["childImageSharp"]["fluid"],
      rating: 5
    }
  ]
  const secondCarousel: ReviewItemInterface[] = [
    {
      name: "Curtis Holcomb",
      role: "Tenant",
      description: "I didn't really want to move away from my parents. But " +
        "that's what it’s like when parents are Tik-Tok fans.",
      photo: carouselImages["image6"]["childImageSharp"]["fluid"],
      rating: 4
    },
    {
      name: "Dliyah Mccullough",
      role: "Property owner",
      description: "Interacting with people sucks. I wanted to sell" +
        " my apartment and reached out to an agent who did all the dirty work.",
      photo: carouselImages["image7"]["childImageSharp"]["fluid"],
      rating: 4
    },
    {
      name: "Rosana Espinosa",
      role: "Tenant",
      description: "This is the only service that gives certainty" +
        " that I don't have to kiss rats at night. Not anymore. Brrr.",
      photo: carouselImages["image8"]["childImageSharp"]["fluid"],
      rating: 5
    }
  ]

  const carouselSettings = {
    infinite: true,
    centerMode: true,
    draggable: false,
    variableWidth: true,
    pauseOnHover: false,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 0,
    speed: 30000,
    cssEase: 'linear',
    slidesToShow: 1,
    slidesToScroll: 1
  }

  return (
    <section className={classNames("twins", styles.wrap)}>
      <div className={classNames('container', styles['main-container'])}>
        <Title>what people say about us</Title>
        <Description className={styles.desc}>
          People are the key in our work. We work with them and for them.
          As an employer we believe in our colleagues and support them.
          As a product company we do our best to deliver our customers
          (both current and future) the best possible experience to make them
          feel happy and satisified. We want you to stay with us beacuse
          we love you.
        </Description>

        <div className={styles["counter__wrap"]}>
          <Counter
            big
            title="Flats & houses"
            className={styles.counter}
          >
            45k+
          </Counter>
          <Counter
            big
            title="New ads daily"
            className={styles.counter}
          >
            120
          </Counter>
          <Counter
            big
            title="Views monthly"
            className={styles.counter}
          >
            957k
          </Counter>
          <Counter
            big
            title="In-house experts"
            className={styles.counter}
          >
            5k
          </Counter>
        </div>
      </div>

      <WindowWidthConsumer>
        {width => (
          <>
            {width > tablet ? (
              <div className={classNames("container", "lg", styles.container)}>
                <div className={classNames(styles.list, 'slick')}>
                  <Slider {...carouselSettings}>
                    {firstCarousel.map((item, index) => (
                        <ReviewItem
                          key={`fc-${index}`}
                          {...item}
                        />
                      )
                    )}
                  </Slider>
                </div>

                <div className={classNames(styles.list, 'slick')}>
                  <Slider {...{ ...carouselSettings, rtl: true }}>
                    {secondCarousel.map((item, index) => (
                        <ReviewItem
                          key={`sc-${index}`}
                          {...item}
                        />
                      )
                    )}
                  </Slider>
                </div>
              </div>
            ) : (
              <div className={classNames(styles.list, 'slick')}>
                <Slider
                  {...{
                    ...carouselSettings,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: false,
                    autoplay: false,
                    speed: 500,
                    rows: 3
                  }}
                >
                  {[...firstCarousel, ...secondCarousel].map((item, index) => (
                      <ReviewItem
                        key={index}
                        {...item}
                      />
                    )
                  )}
                </Slider>
              </div>
            )}
          </>
        )}
      </WindowWidthConsumer>
    </section>
  )
}

export default Reviews