import React, { FC, ReactElement } from "react"
import classNames from "classnames"

import ServicesItem, {
  ServicesItemInterface,
} from "./components /services-item/ServicesItem"
import ServicesList from "./components /services-list/ServicesList"

import FirstService from "assets/icons/services/service-1.svg"
import SecondService from "assets/icons/services/service-2.svg"
import ThirdService from "assets/icons/services/service-3.svg"

import styles from "./Services.module.scss"

const Services: FC = (): ReactElement => {
  // Fake data
  const servicesData: ServicesItemInterface[] = [
    {
      image: FirstService,
      title: "Sell your property",
      description:
        "We help you negotiate to make sure that you get the best " +
        "price for your home. We help you with all administrative details all " +
        "the way to the signature at the notary.",
      link: {
        text: "Learn more",
        path: "/",
      },
    },
    {
      image: SecondService,
      title: "Lease your apartment",
      description:
        "We centralise listings of best quality apartments and" +
        " houses for rent. No need to browse different websites and waste time." +
        " We have the best offers.",
      link: {
        text: "Get started",
        path: "/",
      },
    },
    {
      image: ThirdService,
      title: "Get an assistance",
      description:
        "Our specialists will help you with real estate matters. " +
        "From contact with tenants to real estate agent services." +
        " We can organize apartment and take care of all the papers.",
      link: {
        text: "See how it works",
        path: "/",
      },
    },
  ]

  // Services elements
  const servicesElements: ReactElement[] = servicesData.map((props, index) => (
    <ServicesItem {...props} key={index} />
  ))

  return (
    <section className={classNames("container", styles.wrap)}>
      <ServicesList>{servicesElements}</ServicesList>
    </section>
  )
}

export default Services
