import React, { FC, InputHTMLAttributes, ReactElement } from "react"

import FormItem from "../form-item/FormItem"
import Label from "../label/Label"

import styles from "./Radio.module.scss"

interface RadioInterface extends InputHTMLAttributes<HTMLInputElement> {
  label?: string
}

const Radio: FC<RadioInterface> = (props): ReactElement => {
  const {
    id,
    label
  } = props

  return (
    <FormItem className={styles["form-item"]}>
      <input
        type="radio"
        {...props}
        className={styles.wrap}
      />

      {label && (
        <Label
          className={styles.label}
          htmlFor={id}
        >
          {label}
        </Label>
      )}
    </FormItem>
  )
}

export default Radio
