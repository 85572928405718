import React, { FC, ReactElement } from "react"
import { Link } from "gatsby"

import NavigationDropdown from "../navigation-dropdown/NavigationDropdown"

import styles from "./Navigation.module.scss"

const Navigation: FC = (): ReactElement => {
  return (
    <nav className={styles.wrap}>
      <Link
        className={styles.link}
        to="/"
      >
        For sale
      </Link>
      <Link
        className={styles.link}
        to="/"
      >
        To rent
      </Link>
      <NavigationDropdown/>
      <Link
        className={styles.link}
        to="/"
      >
        Blog
      </Link>
    </nav>
  )
}

export default Navigation
