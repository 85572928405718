import React, { FC, ReactElement, useState, useEffect } from "react"
import Slider from "react-slick"
import classNames from "classnames"

import Title from "../../ui/title/Title"
import Description from "../../ui/description/Description"
import Info from "../../info/Info"
import OfferList from "./components/offer-list/OfferList"
import OfferItem, {
  OfferItemInterface,
} from "./components/offer-item/OfferItem"
import Button from "../../ui/button/Button"
import {
  Consumer as WindowWidthConsumer,
  desktop,
  tablet,
} from "../../../utils/window-width-provider/WindowWidthProvider"

import styles from "./Offer.module.scss"

const data: OfferItemInterface[] = [
  {
    amount: "2,7k",
    title: "Spacious lofts",
    description: "Starting from 1720$/mo",
  },
  {
    amount: "1,2k",
    title: "Cozy houses",
    description: "Starting from 2360$/mo",
  },
  {
    amount: "1,1k",
    title: "Small-size flats",
    description: "Starting from 630$/mo",
  },
  {
    favorite: true,
    amount: "250",
    title: "Luxury mansions",
    description: "Starting from 5000$/mo",
  },
  {
    amount: "752",
    title: "Kid-friendly huts",
    description: "Starting from 1372$/mo",
  },
]

const carouselSettings = {
  infinite: false,
  centerMode: true,
  variableWidth: true,
  arrows: false,
  cssEase: "linear",
}

const Offer: FC = (): ReactElement => {
  const [renderedOffers, setRenderedOffers] = useState(null)
  useEffect(() => {
    setTimeout(() => {
      setRenderedOffers(
        <section className={classNames("container", "wide", styles.wrap)}>
          <Info>
            <Title>Universe of choices</Title>
            <Description>
              We care about your comfort. Thanks to our advanced categorisation
              and filtering system, estate owners have the opportunity to
              describe their property as accurately as possible. Are you looking
              for a spacious loft or maybe you are a solo player that prefers
              small and neat spaces? We got everything. Find what suits best for
              you.
            </Description>
          </Info>

          <WindowWidthConsumer>
            {width => (
              <>
                {width >= desktop ? (
                  <OfferList>
                    {data.map((item, index) => (
                      <OfferItem {...item} key={index} />
                    ))}
                  </OfferList>
                ) : (
                  <Slider
                    {...carouselSettings}
                    centerMode={!(width < desktop && width >= tablet)}
                    slidesToShow={width < desktop && width >= tablet && 2}
                  >
                    {data.map((item, index) => (
                      <OfferItem {...item} key={index} tag="div" />
                    ))}
                  </Slider>
                )}
              </>
            )}
          </WindowWidthConsumer>

          <div className={styles.actions}>
            <Button link="/" className={styles.btn}>
              Show everything
            </Button>
          </div>
        </section>
      )
    }, 300)
  }, [])
  return renderedOffers
}

export default Offer
