import React, { FC, ReactElement } from "react"

import styles from "./ServicesList.module.scss"

const ServicesList: FC = ({
                            children
                          }): ReactElement => {
  return (
    <ul className={styles.wrap}>
      {children}
    </ul>
  )
}

export default ServicesList
