import React, { FC, Fragment, ReactElement, SelectHTMLAttributes } from "react"

import FormItem from "../form-item/FormItem"

import styles from "./Select.module.scss"

interface SelectInterface extends SelectHTMLAttributes<HTMLSelectElement> {
  options: string[]
}

const Select: FC<SelectInterface> = (props): ReactElement => {
  const {
    options
  } = props

  return (
    <FormItem className={styles["form-item"]}>
      <select
        {...props}
        defaultValue={options[0]}
        className={styles.wrap}
      >
        {options.map((option, index) => (
          <Fragment key={index}>
            {index === 0 ? (
              <option
                disabled
                value={option}
              >
                {option}
              </option>
            ) : (
              <option>{option}</option>
            )}
          </Fragment>
        ))}
      </select>
    </FormItem>
  )
}

export default Select
