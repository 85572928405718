import React, { FC, ReactElement } from "react"
import classNames from "classnames"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"

import HeroForm from "./components/hero-form/HeroForm"
import HeroInfo from "./components/hero-info/HeroInfo"

import styles from "./Hero.module.scss"

const Hero: FC = (): ReactElement => {
  const data = useStaticQuery(graphql`
    query {
      bgImage: file(relativePath: { eq: "hero/hero-bg.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <BackgroundImage
      fluid={data.bgImage.childImageSharp.fluid}
      alt=""
      className={styles.wrap}
    >
      <div className={classNames("container", styles.container)}>
        <HeroForm />
        <HeroInfo />
      </div>
    </BackgroundImage>
  )
}

export default Hero
