import React, { FC, HTMLAttributes, ReactElement } from "react"
import classNames from "classnames"

import styles from "./BurgerButton.module.scss"

interface BurgerButtonInterface extends HTMLAttributes<HTMLButtonElement> {
  open?: boolean
}

const BurgerButton: FC<BurgerButtonInterface> = ({
  className,
  open,
  ...rest
}): ReactElement => {
  return (
    <button
      className={classNames(
        "hidden-text",
        styles.wrap,
        className,
        open ? "icon-cross" : "icon-menu"
      )}
      {...rest}
    >
      Burger
    </button>
  )
}

export default BurgerButton
