import React, { FC, HTMLAttributes, ReactElement } from "react"
import classNames from "classnames"

import Navigation from "../navigation/Navigation"

import styles from "./MobileNavigation.module.scss"

interface MobileNavigationInterface extends HTMLAttributes<HTMLElement> {
  open?: boolean
}

const MobileNavigation: FC<MobileNavigationInterface> = ({
  open,
  ...rest
}): ReactElement => {
  return (
    <div className={classNames(styles.wrap, open && styles.open)} {...rest}>
      <Navigation />
    </div>
  )
}

export default MobileNavigation
