import React, { FC, ReactElement } from "react"

import Button from "../../../../ui/button/Button"

import styles from "./ServicesItem.module.scss"

export interface ServicesItemInterface {
  image: any
  title: string
  description: string
  link: {
    text: string
    path: string
  }
}

const ServicesItem: FC<Partial<ServicesItemInterface>> = ({
  image: Image,
  title,
  description,
  link: { text: linkText, path: linkPath },
}): ReactElement => {
  return (
    <li className={styles.wrap}>
      <Image className={styles.image} />

      <h3 className={styles.title}>{title}</h3>

      <div className={styles.description}>{description}</div>

      <Button reverse className={styles.btn} link={linkPath}>
        {linkText}
      </Button>
    </li>
  )
}

export default ServicesItem
