import React, { FC, HTMLAttributes, ReactElement } from "react"
import classNames from "classnames"

import styles from "./Description.module.scss"

interface DescriptionInterface extends HTMLAttributes<HTMLElement> {
}

const Description: FC<DescriptionInterface> = ({
                                                 children,
                                                 className
                                               }): ReactElement => {
  return (
    <div className={classNames(styles.wrap, className)}>
      {children}
    </div>
  )
}

export default Description
