import React, { FC, ReactElement } from "react"
import classNames from "classnames"

import Img, { FluidObject } from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
import FooterLinks, {
  FooterLinksItemsInterface,
} from "./components /footer-links/FooterLinks"
import FooterSocialLinks from "./components /footer-social-links/FooterSocialLinks"

import styles from "./Footer.module.scss"

const Footer: FC = (): ReactElement => {
  const logo: FluidObject = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 220) {
            src
            srcSet
            base64
            sizes
            aspectRatio
          }
        }
      }
    }
  `)

  const commonLinks: FooterLinksItemsInterface = [
    {
      name: "About us",
      path: "/",
    },
    {
      name: "Help Center",
      path: "/",
    },
    {
      name: "Price list",
      path: "/",
    },
    {
      name: "Terms of cooperation",
      path: "/",
    },
    {
      name: "Privacy Policy",
      path: "/",
    },
    {
      name: "Press service",
      path: "/",
    },
    {
      name: "Blog",
      path: "/",
    },
  ]
  const servicesAndToolsLinks: FooterLinksItemsInterface = [
    {
      name: "Advertising",
      path: "/",
    },
    {
      name: "Office tools",
      path: "/",
    },
    {
      name: "Sample contracts",
      path: "/",
    },
    {
      name: "Sell with us",
      path: "/",
    },
  ]
  const siteMapLinks: FooterLinksItemsInterface = [
    {
      name: "Category map",
      path: "/",
    },
    {
      name: "Terrain map",
      path: "/",
    },
    {
      name: "Articles and tips",
      path: "/",
    },
    {
      name: "Database companies",
      path: "/",
    },
    {
      name: "Career",
      path: "/",
    },
  ]

  return (
    <footer className={styles.wrap}>
      <div className={classNames("container", styles.container)}>
        <Img
          fluid={logo["logo"]["childImageSharp"]["fluid"]}
          className={styles.logo}
        />

        <FooterLinks links={commonLinks} />
        <FooterLinks title="Services and tools" links={servicesAndToolsLinks} />
        <FooterLinks mobileIndent title="Site map" links={siteMapLinks} />
        <FooterSocialLinks />
      </div>
      <div className={classNames("container", styles.caption)}>
        <p>
          Made by{" "}
          <span>
            <a href="https://www.unikorns.work/">Unikorns</a>
          </span>
        </p>
      </div>
    </footer>
  )
}

export default Footer
