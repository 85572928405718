import React, { FC, HTMLAttributes, ReactElement } from "react"
import classNames from "classnames"

import styles from "./Star.module.scss"

export interface StarInterface extends HTMLAttributes<HTMLElement> {
  filledIn?: boolean
}

const Star: FC<StarInterface> = ({
                                   filledIn,
                                   className
                                 }): ReactElement => {
  return (
    <span className={classNames(styles.wrap, className, filledIn && styles["filled-in"])}>

    </span>
  )
}

export default Star
