import React, { FC, InputHTMLAttributes, ReactElement } from "react"
import classNames from "classnames"

import FormItem from "../form-item/FormItem"

import styles from "./Input.module.scss"

interface InputInterface extends InputHTMLAttributes<HTMLInputElement> {
  icon?: string
}

const Input: FC<InputInterface> = ({
                                     className,
                                     type,
                                     icon,
                                     ...rest
                                   }): ReactElement => {
  return (
    <FormItem>
      {icon && (
        <i className={classNames("hidden-text", `icon-${icon}`, styles.icon)}>
          {icon}
        </i>
      )}

      <input
        className={classNames(styles.wrap, className)}
        type={type}
        {...rest}
      />
    </FormItem>
  )
}

export default Input
