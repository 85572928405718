import React, { Component, Context, createContext, HTMLAttributes } from "react"

export const {Provider, Consumer} = createContext(0);
export const desktop = 1024, tablet = 768, mobile = 320;

interface WindowWidthProviderInterface {
  width: number,
}

class WindowWidthProvider extends Component<HTMLAttributes<HTMLElement>, WindowWidthProviderInterface> {
  state: WindowWidthProviderInterface = {
    width: 0
  };

  componentDidMount() {
    this.setState({
      width: window.outerWidth
    });

    window.addEventListener('resize', this._resizeHandler.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._resizeHandler.bind(this));
  }

  private _resizeHandler() {
    this.setState({
        width: window.outerWidth
      });
    };

  render() {
    const {
      children
    } = this.props;

    return (
      <Provider value={this.state.width}>
        {children}
      </Provider>
    )
  }
}

export default WindowWidthProvider;