import React, { FC, ReactElement } from "react"

import styles from "./OfferList.module.scss"

const OfferList: FC = ({
                         children
                       }): ReactElement => {
  return (
    <ul className={styles.wrap}>
      {children}
    </ul>
  )
}

export default OfferList
