import React, { FC, HTMLAttributes, ReactElement } from "react"
import classNames from "classnames"

import styles from "./FooterLinksTitle.module.scss"

interface FooterLinksTitleInterface extends HTMLAttributes<HTMLElement> {

}

const FooterLinksTitle: FC<FooterLinksTitleInterface> = ({
                                                           children,
                                                           className
                                                         }): ReactElement => {
  return (
    <>
      {children && (
        <h3 className={classNames(styles.wrap, className)}>
          {children}
        </h3>
      )}
    </>
  )
}

export default FooterLinksTitle
