import React, { FC, ReactElement } from "react"

import styles from "./Info.module.scss"

const Info: FC = ({
                    children
                  }): ReactElement => {
  return (
    <div className={styles.wrap}>
      {children}
    </div>
  )
}

export default Info
