import React, { FC, HTMLAttributes, ReactElement } from "react"
import classNames from "classnames"

import FooterLink, { FooterLinkInterface } from "../footer-link/FooterLink"
import FooterLinksTitle from "../footer-links-title/FooterLinksTitle"

import styles from "./FooterLinks.module.scss"

export type FooterLinksItemsInterface = FooterLinkInterface[];

interface FooterLinksInterface extends HTMLAttributes<HTMLElement> {
  title?: string,
  links: FooterLinksItemsInterface,
  mobileIndent?: boolean
}

const FooterLinks: FC<FooterLinksInterface> = ({
                                                 className,
                                                 title,
                                                 links,
                                                 mobileIndent
                                               }): ReactElement => {
  return (
    <div className={classNames(styles.wrap, className, mobileIndent && styles['mobile-indent'])}>
      <FooterLinksTitle>{title}</FooterLinksTitle>

      <ul className={styles.list}>
        {links.map((link, index) => (
          <FooterLink
            key={index}
            {...link}
          />
        ))}
      </ul>
    </div>
  )
}

export default FooterLinks
