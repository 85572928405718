import React, { FC, ReactElement } from "react"
import Img, { FluidObject } from "gatsby-image"

import Location from "../../../../location /Location"
import Price from "../../../../price/Price"

import styles from "./NewcomersItem.module.scss"

export interface NewcomersItemInterface {
  image: FluidObject,
  title: string,
  location: string,
  price: string
}

const NewcomersItem: FC<Partial<NewcomersItemInterface>> = ({
                                                              image,
                                                              title,
                                                              location,
                                                              price
                                                            }): ReactElement => {
  return (
    <li className={styles.wrap}>
      <Img
        fluid={image}
        className={styles.image}
      />

      <h3 className={styles.title}>{title}</h3>

      <Location>{location}</Location>

      <Price>{price}</Price>
    </li>
  )
}

export default NewcomersItem
