import React, { FC, ReactElement, useState } from "react"
import { Link } from "gatsby"
import classNames from "classnames"

import styles from "./NavigationDropdown.module.scss"
import { Consumer as WindowWidthConsumer, desktop } from "../../../../utils/window-width-provider/WindowWidthProvider"

const NavigationDropdown: FC = (): ReactElement => {
  const [open, changeOpenState] = useState<boolean>(false)

  return (

    <WindowWidthConsumer>
      {width => (
        <>
          <div
            className={classNames(styles.wrap, open && styles.open)}
            onMouseEnter={() => width > desktop && changeOpenState(true)}
            onMouseLeave={() => width > desktop && changeOpenState(false)}
            onClick={() => width <= desktop && changeOpenState(!open)}
          >
            <button
              className={styles["dropdown-action-btn"]}
            >
              Developer offers
            </button>

            <div className={styles["dropdown-content"]}>
              <Link
                className={styles.link}
                to="/"
              >
                New apartments
              </Link>
              <Link
                className={styles.link}
                to="/"
              >
                New houses
              </Link>
            </div>
          </div>
        </>
      )}
    </WindowWidthConsumer>
  )
}

export default NavigationDropdown
