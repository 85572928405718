import React, { FC, ReactElement } from "react"
import classNames from "classnames"

import Title from "../../ui/title/Title"
import Description from "../../ui/description/Description"
import Button from "../../ui/button/Button"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import styles from "./Report.module.scss"

const Report: FC = (): ReactElement => {
  const data = useStaticQuery(graphql`
    query {
      chart: file(relativePath: { eq: "report/chart.png" }) {
        childImageSharp {
          fluid(maxWidth: 556, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <section className={classNames("container", styles.wrap)}>
      <Img fluid={data.chart.childImageSharp.fluid} className={styles.image} />

      <div className={styles.info}>
        <Title>Property prices</Title>
        <Description className={styles.description}>
          We’re not only “post & search” platform. Admit that you knew it.
          Thanks to our developed AI, we are able to process huge data massives,
          analyze current market situation and make predictions. For now
          everything is ok. Just saying.
        </Description>

        <Button link="/" className={styles.btn}>
          Take a look at our reports
        </Button>
      </div>
    </section>
  )
}

export default Report
