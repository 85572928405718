import React, { FC, ReactElement } from "react"

import styles from "./Location.module.scss"

const Location: FC = ({
                        children
                      }): ReactElement => {
  return (
    <>
      {children && (
        <span className={styles.wrap}>
          {children}
       </span>
      )}
    </>
  )
}

export default Location
