import React, { FC, ReactElement } from "react"

import Counter from "../../../../counter/Counter"
import Star from "../../../../star/Star"

import styles from "./OfferItem.module.scss"

export interface OfferItemInterface {
  favorite?: boolean,
  amount: string,
  title: string,
  description: string
  tag?: "li" | "div"
}

const OfferItem: FC<OfferItemInterface> = ({
                                             favorite,
                                             amount,
                                             title,
                                             description,
                                             tag: Tag = "li"
                                           }): ReactElement => {
  return (
    <Tag className={styles.wrap}>
      <div className={styles.info}>
        <Counter className={styles.counter}>{amount}</Counter>

        <div className={styles.lines}>
          <h3 className={styles.title}>{title} {favorite && <Star filledIn/>}</h3>
          <div className={styles.desc}>{description}</div>
        </div>
      </div>
    </Tag>
  )
}

export default OfferItem
