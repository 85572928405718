import React, { FC, FunctionComponent, ReactElement } from "react"
import Img, { FluidObject } from "gatsby-image"

import styles from "./ReviewItem.module.scss"
import Star, { StarInterface } from "../../star/Star"

export interface ReviewItemInterface {
  name: string,
  role: 'Property owner' | 'Tenant',
  description: string,
  photo: FluidObject,
  rating: 1 | 2 | 3 | 4 | 5
}

const ReviewItem: FC<ReviewItemInterface> = ({
                                                       name,
                                                       role,
                                                       description,
                                                       photo,
                                                       rating
                                                     }): ReactElement => {
  const ratingStars: FunctionComponent<StarInterface>[] = [
    Star,
    Star,
    Star,
    Star,
    Star
  ];

  return (
    <div className={styles.wrap}>
      <div className={styles.top}>
        <Img
          fluid={photo}
          className={styles.image}
        />

       <div className={styles.info}>
         <h3 className={styles.title}>{name}</h3>
         <span className={styles.role}>{role}</span>
       </div>
      </div>

      <div className={styles.desc}>{description}</div>

      <div>
        {ratingStars.map((Component, index) => {
          if (index >= rating) {
            return <Component key={index} className={styles.star}/>;
          }

          return <Component key={index} className={styles.star} filledIn/>;
        })}
      </div>
    </div>
  )
}

export default ReviewItem