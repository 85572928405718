import React, { FC, ReactElement } from "react"
import { Link } from "gatsby"

import styles from "./FooterLink.module.scss"

export interface FooterLinkInterface {
  name: string,
  path: string
}

const FooterLink: FC<FooterLinkInterface> = ({
                                               name,
                                               path
                                             }): ReactElement => {
  return (
    <li className={styles.wrap}>
      <Link to={path}>{name}</Link>
    </li>
  )
}

export default FooterLink
