import React, { FC, HTMLAttributes, ReactElement } from "react"
import classNames from "classnames"

import styles from "./Counter.module.scss"

interface CounterInterface extends HTMLAttributes<HTMLElement> {
  big?: boolean,
  title?: string
}

const Counter: FC<CounterInterface> = ({
                                         big,
                                         title,
                                         children,
                                         className,
                                         ...rest
                                       }): ReactElement => {
  return (
    <>
      {children && (
        <span
          className={classNames(styles.wrap, className, big && styles.big)}
          {...rest}
        >
          {children}
          {title && <h3 className={styles.title}>{title}</h3>}
        </span>
      )}
    </>
  )
}

export default Counter
