import React, { FC, ReactElement, useEffect, useState } from "react"
import classNames from "classnames"

import Navigation from "./components/navigation/Navigation"
import MyAccount from "./components/my-account/MyAccount"
import BurgerButton from "../ui/burger-button/BurgerButton"
import MobileNavigation from "./components/mobile-navigation/MobileNavigation"

import LogoSvg from "assets/icons/logo.svg"

import styles from "./Header.module.scss"

const Header: FC = (): ReactElement => {
  const [navigationOpen, openNavigation] = useState<boolean>(false);
  const [isScrolled, changeScrolledState] = useState<boolean>(false);

  const changeScrolledHeaderColor = (position: number): void => {
    if (position + 30 < 0) {
      changeScrolledState(true);
      return;
    }

    changeScrolledState(false);
  }

  const scrollHandler = () => {
    const {y} = document.body.getBoundingClientRect();

    changeScrolledHeaderColor(y);
  };

  // Makes non scrollable body while the menu is open
  useEffect(() => {
    if (document && document.body && navigationOpen) {
      document.body.classList.add('non-scrollable');
    } else {
      document.body.classList.remove('non-scrollable');
    }
  }, [navigationOpen]);

  // Changes header`s color if document was scrolled
  useEffect(() => {
    if (document && document.body) {
      document.addEventListener('scroll', scrollHandler);
    }

    return () => document.removeEventListener('scroll', scrollHandler);
  });

  return (
    <header className={classNames(styles.wrap, isScrolled && styles.scrolled)}>
      <div className={classNames("container", "lg", styles.container)}>

        <LogoSvg className={styles.logo}/>

        <div className={styles.content}>
          <Navigation/>
          <MyAccount/>
        </div>

        <MobileNavigation open={navigationOpen}/>

        <BurgerButton
          open={navigationOpen}
          onClick={() => openNavigation(!navigationOpen)}
        />
      </div>
    </header>
  )
}

export default Header
