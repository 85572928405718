import React, { FC, ReactElement } from "react"

import styles from "./Price.module.scss"

const Price: FC = ({
                     children
                   }): ReactElement => {
  return (
    <>
      {children && (
        <span className={styles.wrap}>
          {children}
        </span>
      )}
    </>
  )
}

export default Price
