import React, { FC, ReactElement } from "react"
import { Link } from "gatsby"
import classNames from "classnames"

import Button from "../../../ui/button/Button"

import styles from "./MyAccount.module.scss"

const MyAccount: FC = (): ReactElement => {
  return (
    <div className={styles.wrap}>
      <Link
        className={styles.link}
        to="/"
      >
        <i className={classNames("hidden-text", "icon-profile", styles.icon)}>My account</i>
        My account
      </Link>

      <Button className={styles.btn}>Post your ad</Button>
    </div>
  )
}

export default MyAccount
